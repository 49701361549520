import React from 'react';

import Modal from './modal';

import { emaily } from '../../Data';

const Emaily = (props) => {
	return <Modal {...emaily} {...props} />;
};

export default Emaily;
