import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import HomeSection from './misc/HomeSection';
import AboutSection from './about/AboutSection';
import ProjectsSection from './projects/ProjectsSection';
import ContactSection from './contact/ContactSection';
import Footer from './misc/Footer';
import Yelpcamp from './modals/Yelpcamp';
import Emaily from './modals/Emaily';
import Marioplan from './modals/Marioplan';

const Portfolio = () => {
	return (
		<React.Fragment>
			<HomeSection />
			<AboutSection />
			<ProjectsSection />
			<ContactSection />
			<Footer />
		</React.Fragment>
	);
};

const App = () => {
	return (
		<BrowserRouter>
			<div>
				<Route path="/" component={Portfolio} />
				<Route path="/yelpcamp" component={Yelpcamp} />
				<Route path="/emaily" component={Emaily} />
				<Route path="/marioplan" component={Marioplan} />
			</div>
		</BrowserRouter>
	);
};

export default App;
