import React from 'react';

import Principles from './Principles';
import Me from './Me';
import Skills from './Skills';

const About = () => {
  return (
    <section className="section" id="about">
      <div className="title center">
        <h2>About Me</h2>
        <p>Who am I?</p>
      </div>
      <Me />
      <Skills />
      <Principles />
    </section>
  )
};

export default About;