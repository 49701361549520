import React from 'react';

import DisplayProject from './DisplayProject';

import { yelpcamp, emaily, marioplan } from '../../Data';

const Projects = () => {
	return (
		<section className="section" id="projects">
			<div className="dark">
				<div className="title center">
					<h2>Projects</h2>
				</div>
				<div className="projects">
					<DisplayProject {...yelpcamp} />
					<DisplayProject {...emaily} />
					<DisplayProject {...marioplan} />
				</div>
			</div>
		</section>
	);
};

export default Projects;
