export const principles = [
	{
		title: 'Fast',
		info: 'Lag free interaction and fast loading is my highest priority.',
		icon: 'fas fa-running',
	},
	{
		title: 'Responsive',
		info: 'My layouts are planned and designed to work on any device; big or small.',
		icon: 'fas fa-mobile-alt',
	},
	{
		title: 'Intuitive',
		info: 'Guaranteed easy-to-use and intuitive UX/UI.',
		icon: 'far fa-lightbulb',
	},
	{
		title: 'Dynamic',
		info: "I enjoy making pages come to life, they don't have to be static.",
		icon: 'fas fa-rocket',
	},
];

export const skills = [
	{
		name: 'HTML',
		icon: 'fab fa-html5',
	},
	{
		name: 'CSS',
		icon: 'fab fa-css3-alt',
	},
	{
		name: 'JavaScript',
		icon: 'fab fa-js',
	},
	{
		name: 'Node.js',
		icon: 'fab fa-node-js',
	},
	{
		name: 'React',
		icon: 'fab fa-react',
	},
];

export const yelpcamp = {
	title: 'YelpCamp',
	language: 'Node.js',
	description:
		"Yelp Camp is a personal project that allows you to view campgrounds with a short description. Once you login or signup you can create, edit or delete your own campgrounds or leave comments on other campgrounds. It's built with Node.js and Express on the back-end with Passport authorization and it's connected to a non-sql MongoDB database. It's styled using Bootstrap.",
	link: 'yelpcamp',
	site: 'https://pieter-yelpcamp.herokuapp.com/',
	github: 'https://github.com/PieterPS/yelpcamp',
	images: ['./images/yelpcamp1.jpg', './images/yelpcamp2.jpg'],
};

export const emaily = {
	title: 'Emaily',
	language: 'React & Node.js',
	description:
		"Emaily is a personal project that allows you to send surveys via bulk-email to customers/users quick and easily. Recipients can respond to the surveys which will be displayed on your dashboard. It's built with React and Redux on the front-end with Materialize styling and Node.js on the back-end along with Express, Passport authorization, Sendgrid's email-api and Stripe-api payments - connected to a non-sql MongoDB database.",
	link: 'emaily',
	site: 'https://pieter-emaily.herokuapp.com/',
	github: 'https://github.com/PieterPS/emaily',
	images: ['./images/emaily1.jpg', './images/emaily2.jpg'],
};

export const marioplan = {
	title: 'Marioplan',
	language: 'React',
	description:
		'Marioplan is a personal project developed that allows you to sign up and post a project or struggle related to Mario games. It is built with React with Materialize styling and connected to cloud firestore database to provide realtime notifications when someone signs up or posts a project.',
	link: 'marioplan',
	site: 'https://marioplan.pieterpretorius.com/',
	github: 'https://github.com/PieterPS/marioplan',
	images: ['./images/marioplan1.jpg', './images/marioplan2.jpg'],
};
