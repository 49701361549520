import React, { useRef, useEffect } from 'react';

const HomeSection = () => {
  const span1 = useRef();
  const span2 = useRef();

  useEffect(() => {  
    const text1 = "Hello, I'm Pieter Pretorius.";
    let a = 0;
    const text2 = "I'm a full-stack web developer.";
    let b = 0;
    const speed = 100;

    const typeWriter = () => {
      if (a < text1.length) {
        span1.current.innerHTML += text1.charAt(a);
        a++;
        setTimeout(typeWriter, speed);
      } else if (b < text2.length) {
        span2.current.innerHTML += text2.charAt(b);
        b++;
        setTimeout(typeWriter, speed);
      } else {
        return null;
      }
    }

    setTimeout(typeWriter, 1000)
  }, []);
  
  return (
    <header id="home">
      <div className="dark">
        <div className="home-container">
          <h1>
            <span ref={span1}></span>
            <span id="typer-1">|</span>
            <br />
            <span ref={span2}></span>
            <span id="typer-2">|</span>
          </h1>
          <a href="#about" title="View Portfolio" className="btn-bounce">
            <i className="fas fa-angle-double-down" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default HomeSection;