import React from 'react';

import Modal from './modal';

import { yelpcamp } from '../../Data';

const Yelpcamp = (props) => {
	return <Modal {...yelpcamp} {...props} />;
};

export default Yelpcamp;
