import React from 'react';
import { Link } from 'react-router-dom';

const DisplayProject = ({ title, language, link }) => {
  return (
    <div className="project">
      <div className="image-p">
        <div id={link} alt={`${link} image`} />
      </div>
      <div className="info-p">
        <h4>{title}</h4>
        <h5>{language}</h5>
        <Link to={`/${link}`} className="project-link">Learn More</Link>
      </div>
    </div>
  );
};

export default DisplayProject;