import React from 'react';

import ContactForm from './ContactForm';

const ContactSection = () => {
  return (
    <section className="section dark" id="contact">
      <div className="container">
        <div className="fragment-4">
          <div className="title">
            <h2>Contact</h2>
            <p>Get in touch</p>
          </div>
          <p className="c-text">Have a question or want to work together?</p>
        </div>
        <div className="fragment-6">
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default ContactSection;