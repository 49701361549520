import React, { useState } from 'react';
import { saveMessage } from '../../services/firebase';

const ContactForm = () => {
	const [status, setStatus] = useState('');
	const [form, setForm] = useState({
		name: '',
		email: '',
		message: '',
	});

	const handleChange = (e) => {
		setForm({ ...form, [e.target.id]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const { name, email, message } = form;
		const res = await saveMessage(name, email, message);
		setStatus(res.status);
		if (res.status === 'success') {
			setTimeout(() => {
				setStatus('');
			}, 5000);
		}

		setForm({
			name: '',
			email: '',
			message: '',
		});
	};

	return (
		<React.Fragment>
			{status === 'success' ? <div id="success">Thank you for the message. I will get back to you asap.</div> : ''}
			<form id="contact-form" onSubmit={handleSubmit}>
				<div className="inputs">
					<input
						type="text"
						id="name"
						name="name"
						placeholder="Name"
						onChange={handleChange}
						value={form.name}
						required
					/>
					<input
						type="email"
						id="email"
						name="email"
						placeholder="Email"
						onChange={handleChange}
						value={form.email}
						required
					/>
				</div>
				<textarea
					name="message"
					id="message"
					cols="30"
					rows="8"
					placeholder="Your Message"
					onChange={handleChange}
					value={form.message}
					required
				/>
				{status === 'success' ? (
					<button className="btn">Success</button>
				) : (
					<button type="submit" className="btn">
						Submit
					</button>
				)}
			</form>
		</React.Fragment>
	);
};

export default ContactForm;
