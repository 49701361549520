import React from 'react';

import { principles } from '../../Data';

const Principle = props => {
  return (
    <div id="principles">
        {principles.map(({ title, icon, info }) => {
          return (
            <div className="card light" key={title}>
              <i className={`${icon} card-i`}/>
              <h3 className="card-title">{title}</h3>
              <p className="card-info">{info}</p>
            </div>
          );
        })}
    </div>
  );
};

export default Principle;