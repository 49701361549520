import firebase from 'firebase/app';
import 'firebase/firestore';

// Firebase requirements
const firebaseConfig = {
	apiKey: 'AIzaSyBYxdLRA3huiQCf_hlmjG7Fo5soFCqSK3w',
	authDomain: 'portfolio-738c7.firebaseapp.com',
	databaseURL: 'https://portfolio-738c7.firebaseio.com',
	projectId: 'portfolio-738c7',
	storageBucket: 'portfolio-738c7.appspot.com',
	messagingSenderId: '934812971483',
	appId: '1:934812971483:web:5d9fa5a5b46229cf61b7fe',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export const saveMessage = (name, email, message) => {
	return db
		.collection('messages')
		.add({
			name,
			email,
			message,
		})
		.then(() => {
			return { status: 'success' };
		})
		.catch(() => {
			return { status: 'failure' };
		});
};
