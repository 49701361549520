import React from 'react';

import { skills } from '../../Data';

const Skill = props => {
  return (
    <div>
      <div id="skills">
        {skills.map(skill => {
          return(
            <span className="tile" key={skill.name} >
              <i className={`${skill.icon} tile-i`}/>
              <h3 className="tile-title">{skill.name}</h3>
            </span>
          )
        })}
      </div>
    </div>
  )
};

export default Skill;