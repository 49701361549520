import React, { useState } from 'react';

const Modal = ({ title, language, description, site, github, images, history }) => {
  const [ count, setCount ] = useState(0);

  const handleClick = e => {
    if(e.target.classList.contains("full")) {
      handleClose();
    };
  }

  const handleClose = () => {
    history.push('/');
  }

  const handleSlider = () => {
    if(!count) {
      setCount(1);
    } else {
      setCount(0);
    }
  }

  return (
    <div className="full" onClick={handleClick}>
      <div className="modal">
        <div className="slides">
          <img className={!count ? "slide show" : "slide"} alt={images[0]} src={images[0]} />
          <img className={count ? "slide show" : "slide"} alt={images[1]} src={images[1]} />
          <button onClick={handleSlider} className="btn-left"><i className="fas fa-angle-left"></i></button>
          <button onClick={handleSlider} className="btn-right"><i className="fas fa-angle-right"></i></button>
        </div>
        <div className="information">
          <h2>{title}</h2>
          <p className="right">{language}</p>
          <hr />
          <p>{description}</p>
          <div className="links">
            <a href={site} target="_blank" rel="noopener noreferrer">View Site</a>
            <a href={github} target="_blank" rel="noopener noreferrer">View Github</a>
          </div>
          <button onClick={handleClose} className="close">Close</button>
        </div>
      </div>
    </div>
  );
}
 
export default Modal;