import React from 'react';

import Modal from './modal';

import { marioplan } from '../../Data';

const Marioplan = (props) => {
	return <Modal {...marioplan} {...props} />;
};

export default Marioplan;
