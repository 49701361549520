import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="top">
        <a href="#home" className="btn-bounce" title="Back to Top">
          <i className="fas fa-angle-double-up" />
        </a>
      </div>
      <div className="social-links">
        <a className="social-link" href="https://www.linkedin.com/in/pieter-pretorius-49ba67166/" target="_blank" rel="noopener noreferrer" title="My LinkedIn">
          <i className="fab fa-linkedin-in" />
        </a>
        <a className="social-link" href="https://github.com/PieterPS/" target="_blank" rel="noopener noreferrer" title="My Github">
          <i className="fab fa-github" />
        </a>
      </div>
      <p className="footer-p">
        Pieter Pretorius 
        <span className="highlight"> ©2020</span>
      </p>
    </footer>
  );
};

export default Footer;