import React from 'react';


const Me = () => {
  return (
    <div id="personal">
      <div id="picture" />
      <div id="who">
        <p>
          I'm a passionate and hard working web developer with a love for creating new and aspiring websites that can make a change.
          <span className="highlight">Let's make a change!</span>
        </p>
      </div>
    </div>
  );
};

export default Me;
